import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
// import { Cloudinary } from "cloudinary-react";
import axios from 'axios'



import EnvRoute from '../middleware/envRoute';
const ENDPOINT = EnvRoute('prod'); 



// const APICalls =() =>{


/*--------------------
------Scan Calls-------
----------------------*/

/*----------Create------------*/
export const saveScan = async(obj) =>{
    try {
        // console.log('Save Scan', obj)
        // setLoading(true);
        const config = {
            headers: {
                "Content-type": "application/json",
                //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/scan/`,
            obj,
            config
            );
            // console.log('Scan save response: ',data)
            return data
            
        } catch (err) {console.log('[Scan Save Error]: ',err)}
    }  
    
    /*----------Read------------*/

export const fetchOwnerScans = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/scan/owner`,
          {owner: userID},
           config
       );
    //    console.log("APICalls scans: ",data)
        return data

       }catch(err){console.log('[FetchQRs Error]',err)}
   }


/*--------------------
------follow Calls-------
----------------------*/

/*----------Create------------*/
export const saveFollow = async(obj) =>{
    try {
        // console.log('Save follow', obj)
        // setLoading(true);
        const config = {
            headers: {
                "Content-type": "application/json",
                //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/follow/`,
            obj,
            config
            );
            // console.log('Scan follow response: ',data)
            return data
            
        } catch (err) {console.log('[Follow Save Error]: ',err)}
    }  
    
    /*----------Read------------*/

export const fetchOwnerFollows = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/follow/id`,
          {owner: userID},
           config
       );
    //    console.log("APICalls follows: ",data)
        return data

       }catch(err){console.log('[FetchFollows Error]',err)}
   }

/*--------------------
------Signup Calls-------
----------------------*/

/*----------Create------------*/
export const saveSignup = async(obj) =>{
    try {
        // console.log('Save follow', obj)
        // setLoading(true);
        const config = {
            headers: {
                "Content-type": "application/json",
                //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/signup/`,
            obj,
            config
            );
            // console.log('Scan follow response: ',data)
            return data
            
        } catch (err) {console.log('[Signup Save Error]: ',err)}
    }  
    
    /*----------Read------------*/

export const fetchOwnerSignups = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/signup/id`,
          {owner: userID},
           config
       );
    //    console.log("APICalls follows: ",data)
        return data

       }catch(err){console.log('[Fetchsignups Error]',err)}
   }



/*--------------------
------vCard Calls-------
----------------------*/

/*----------READ------------*/

 export const fetchVCard  = async( persona,token) =>{
      console.log("[fetching vCard] for: ", persona)
      const config = {
          headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    // if(persona){

        try{
            const data  = await axios.post(`${ENDPOINT[0]}/vCard/`,persona, config)
            console.log("fetch persona vCard: ", data?.data)
            return data.data
            
        }catch(err){console.log('[Fetch vCard Error]',err)}
    // }
}


/*--------------------
------Auth Calls-------
----------------------*/
export const Login = async (userObj) => {

     const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
     try {
      const  data  = await axios.post(
        `${ENDPOINT[0]}/auth/login`,
        {
          ...userObj
        },//config,
        { withCredentials: true }
      );
     return data
    } catch (error) {
      console.log(error);
    }
  

}
export const signup = async (userObj) => {
     const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
     try {
      const {data}  = await axios.post(
        `${ENDPOINT[0]}/auth/signup`,
        {
          ...userObj
        },config,
        // { withCredentials: true }
      );
    //   console.log('Data:',data)
     return data
    } catch (error) {
      console.log(error);
    }
  

}
/*--------------------
------User Calls-------
----------------------*/

/*----------CREATE------------*/
export const createUser = async (userObj) => {
    
    // console.log('{createUser] pre-data:',userObj);
    const { firstName ,lastName ,preferredName,email ,password ,confirmPassword ,phone,isOnline,pic,linkage }= userObj
   
  
    try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/reg`, userObj, config
      );
    //   console.log('[createUser] data:',data);
    //   localStorage.setItem("QauRaUser", JSON.stringify(data));
      return data
   
    } catch (err) {console.log('[Create User Error]: ',err)
    }
  };
/*----------READ------------*/
export const userLogin = async (userObj) => {
   

   const {email ,password  }= userObj
    
  
      if ( !email || !password ) {
        return 'missing credentials';
      }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/login`,
        {
          email,
          password,
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Login Error]: ',err)
        return 'unauthorized'
      
    }
        
    }

export const userByID = async (userObj) => {
   

   const {_id ,password,token  }= userObj
    
  
    //   if ( !email || !password ) {
    //     return 'missing credentials';
    //   }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userObj?.token}`

        },
      };
      const { data, } = await axios.post(
        `${ENDPOINT[0]}/user/info`,
        {
          _id,
          token
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Login Error]: ',err)
        return 'unauthorized'
      
    }
        
    }

    export const userByEmail = async (email) => {
   

    
  
      if ( !email ) {
        return 'missing email';
      }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        //   Authorization: `Bearer ${userObj?.token}`

        },
      };
      const { data, } = await axios.post(
        `${ENDPOINT[0]}/user/status`,
        {
         email
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Check Error]: ',err)
        return 'error'
      
    }
        
    }

/*----------UPDATE------------*/
export const updateUser = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                //   email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data.data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const updateLogout = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                  email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const forgotPassword = async(email) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                // Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.post(
                `${ENDPOINT[0]}/user/forgot-password`,
                {email},
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

export const resetPassword = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.post(
                `${ENDPOINT[0]}/user/reset-password`,
                {
                  email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

/*--------------------
------QR Calls-------
----------------------*/

/*----------CREATE------------*/
export const createQR = async(qrCodeData) =>{
    try {
         console.log('QR Save Click', qrCodeData)
        // setLoading(true);
        const config = {
            headers: {
            "Content-type": "application/json",
            //   Authorization: `Bearer ${user.token}`,
            },
        };
        const qrCode ={}= {...qrCodeData}
        const  {data}= await axios.post(
            `${ENDPOINT[0]}/qr/create`,
          {...qrCode},
            config
        );
        console.log('save response: ',data)
        return data
        
    } catch (err) {console.log('[CreateQR Error]: ',err)}
  }  
  
  /*----------READ------------*/
  export const fetchQR  = async(token, asset_id) =>{
      console.log("[fetching Qrs]")
      const config = {
          headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    try{
        const data  = await axios.post(`${ENDPOINT[0]}/qr/check`,{asset_id}, config)
        // console.log("fetchQR: ", data?.data)
        return data
        
    }catch(err){console.log('[FetchQR Error]',err)}
}

  export const fetchQRByID  = async( _id,token) =>{
      console.log("[fetching QR by Id]:",_id)
      const config = {
          headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    try{
        const data  = await axios.post(`${ENDPOINT[0]}/qr/`,{_id}, config)
        if(data){

            console.log("fetchQR By Id: ", data)
            return data
        }else{
            console.log('[FetchQR Error] No QR')
            return }
        
    }catch(err){console.log('[FetchQR Error]',err)}
}

export const fetchQRs = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
        //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/qr/qrs`,
          {owner: userID},
           config
       );
    //    console.log("QRs: ",data)
        return data

       }catch(err){console.log('[FetchQRs Error]',err)}
   }

/*----------UPDATE------------*/
export const updateQRCode = async(obj) =>{
    
      try {
       const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.put(
           `${ENDPOINT[0]}/qr/update`,
          { _id: obj?._id,
            data:`https://qaura.pro/assets/${obj?._id}`,
            ...obj},
           config
       );
       console.log('UpdateQRCode response: ',data)
        return data
   }catch(err){console.log('[updateQRCode Error]: ',err)}
 }  
/*----------DESTROY------------*/
export const deleteQR = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.put(
                `${ENDPOINT[0]}/qr/delete`,
                // {_id: obj?._id,
    
                // },
                {...obj
                },
                config
                );
              console.log('[deleteQRFromDB] - response:', data)
            
            return 'success'
            
        }catch(err){console.log('[DeleteQR Error]: ',err)}   
    }
    export const deleteQRImage = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.put(
                `${ENDPOINT[0]}/qr/imageDelete`,
                // {_id: obj?._id,
    
                // },
                {...obj
                },
                config
                );
              console.log('[deleteQRFromDB] - response:', data)
            
            return 'success'
            
        }catch(err){console.log('[DeleteQR Error]: ',err)}   
    }
    


/*--------------------
------Asset Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createAsset = async (obj,userID,assets) =>{
     const untitledCount = () =>{
        let count = assets?.filter(a=>a?.name.includes('untitled')).length
        return count}
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/asset/create`,
            {
                ...obj,
                owner: userID,
                name: obj?.name ? obj?.name:'untitled asset: #'+untitledCount() 
            },
            config
            );
        return data

       }catch(err){console.log('[CreateAsset Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchAsset =  async (asset_id) => {
        // console.log('Asset_id: ', asset_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const { data } = await axios.post(`${ENDPOINT[0]}/qr/`,{_id:asset_id},config)
            console.log("[Redirect Data]:", data)
            return data
            
        }catch(err){console.log("[Redirect Error]: Fetching Asset")}

    }
export const fetchAssets  = async(userObj) =>{
    console.log("[fetching Assets] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/asset/id`,{_id: id}, config)
                // console.log("[AssetList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchAssets]: ',err)}
            }

/*----------UPDATE------------*/
export const updateAsset = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/asset/update`,
            {
                _id: obj._id,
                ...obj,
            },
            config
            );
            console.log('[updateAssets]: data', data)
        return data
            
    }catch(err){console.log('[UpdateAsset Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteAsset = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/asset/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteAssetFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteAsset Error]: ',err)}   
    }
/*--------------------
------Persona Calls-----
----------------------*/

/*----------CREATE------------*/

export const createPersona = async (obj,userID,personas) =>{
    
         const untitledCount = () =>{
            let count = personas.filter(p=>p.personaTitle.includes('untitled')).length
            return count
         }

    try{
           const config = {
               headers: {
               "Content-type": "application/json",
            },
        };
        //    if(!obj?.owner){
               console.log('[createPersona] How farss the data getting?🤔 :', obj)
        //    }
           const { data } = await axios.post(
               `${ENDPOINT[0]}/persona/create`,
               {
                   ...obj,
                   owner: userID,
                   personaTitle: obj?.personaTitle ? obj?.personaTitle:'untitled persona: #'+untitledCount() 
                },
                config
                );
                return data
                
            }catch(err){console.log('[CreatePersona Error]: ',err)}   
        }
        
/*----------READ------------*/

export const fetchPersona = async (id) =>{
            // console.log("[fetching Persona]")
            const config = {
                headers: {
                "Content-type": "application/json",
            // Authorization: `Bearer ${user?.token}`,
            },
        };
        try{
            const data  = await axios.post(`${ENDPOINT[0]}/persona/id`,{_id:id}, config)
            console.log("[FetchPersona] data: ", data.data)
            return data.data
        }catch(err){console.log('[fetchPersona Error]: ',err)}
    }

export const fetchPersonas  = async(userObj) =>{
        const config = {
            headers: {
            // Authorization: `Bearer ${userObj?.token}`,
            },
        };
        try{
            const data  = await axios.post(`${ENDPOINT[0]}/persona/personas`,{_id: userObj?._id}, config)
            // console.log("[fetchPersonas] data: ", data.data)
            return data.data

        }catch(err){console.log('[fetchPersonas Error]: ',err)}
        }


/*----------UPDATE------------*/
export const updatePersona = async (obj) =>{
         try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/persona/update`,
                {
                    _id: obj?._id,
                    ...obj,
                },
                config
                );
                console.log('[updatePersona]: ', data)
                return data

        }catch(err){console.log('[updatePersona Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deletePersona = async (obj)=>{
    try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.put(
                `${ENDPOINT[0]}/persona/delete`,
                {
                    ...obj,
                    // owner: userData?._id,
                    _id: obj?._id
                },
                config
                );
              console.log('[deletePersonaFromDB] - response:', data)
               return data
        }catch(err){console.log('[DeletePersona Error]: ',err)}   
    }

/*--------------------
------Profile Calls-----
----------------------*/

/*----------CREATE------------*/

export const createProfile = async (obj,userID,profiles) =>{
    
    const untitledCount = () =>{
       let count = profiles.filter(p=>p.profileTitle.includes('untitled')).length
       return count
    }

try{
      const config = {
          headers: {
          "Content-type": "application/json",
       },
   };
   //    if(!obj?.owner){
          console.log('[createProfile] How farss the data getting?🤔 :', obj)
   //    }
      const { data } = await axios.post(
          `${ENDPOINT[0]}/profile/create`,
          {
              ...obj,
              owner: userID,
              profileTitle: obj?.profileTitle ? obj?.profileTitle:'untitled profile: #'+untitledCount() 
           },
           config
           );
           return data
           
       }catch(err){console.log('[CreateProfile Error]: ',err)}   
   }
   
/*----------READ------------*/

export const fetchProfile = async (id) =>{
       // console.log("[fetching Profile]")
       const config = {
           headers: {
           "Content-type": "application/json",
       // Authorization: `Bearer ${user?.token}`,
       },
   };
   try{
       const data  = await axios.post(`${ENDPOINT[0]}/profile/id`,{_id:id}, config)
       console.log("[FetchProfile] data: ", data.data)
       return data.data
   }catch(err){console.log('[fetchProfile Error]: ',err)}
}

export const fetchProfiles  = async(userObj) =>{
   const config = {
       headers: {
       // Authorization: `Bearer ${userObj?.token}`,
       },
   };
   try{
       const data  = await axios.post(`${ENDPOINT[0]}/profile/profiles`,{_id: userObj?._id}, config)
       // console.log("[fetchProfiles] data: ", data.data)
       return data.data

   }catch(err){console.log('[fetchProfiles Error]: ',err)}
   }


/*----------UPDATE------------*/
export const updateProfile = async (obj) =>{
    try{
       const config = {
           headers: {
           "Content-type": "application/json",
           },
       };
       const { data } = await axios.put(
           `${ENDPOINT[0]}/profile/update`,
           {
               _id: obj?._id,
               ...obj,
           },
           config
           );
           console.log('[updateProfile]: ', data)
           return data

   }catch(err){console.log('[updateProfile Error]: ',err)}   
}


/*----------DESTROY------------*/
export const deleteProfile = async (obj)=>{
try{
       const config = {
           headers: {
           "Content-type": "application/json",
           },
       };
       
       const { data } = await axios.put(
           `${ENDPOINT[0]}/profile/delete`,
           {
               ...obj,
               // owner: userData?._id,
               _id: obj?._id
           },
           config
           );
         console.log('[deleteProfileFromDB] - response:', data)
          return data
   }catch(err){console.log('[DeleteProfile Error]: ',err)}   
}
/*--------------------
------Shop Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createShop = async (obj,userID,shops) =>{
     const untitledCount = () =>{
        let count = shops?.filter(a=>a?.name.includes('untitled')).length
        return count}
      try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/shop/create`,
            {
                ...obj,
                // owner: userID,
                // name: obj?.name ? obj?.name:'untitled shop: #'+untitledCount() 
            },
            config
            );
        return data

       }catch(err){console.log('[Createshop Error]: ',err)}   
   }

   
    /*----------READ------------*/
  export const fetchShop =  async (shop_id) => {
        // console.log('shop_id: ', shop_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const {data}  = await axios.post(`${ENDPOINT[0]}/shop/id/`,{_id:shop_id},config)
            console.log("[Redirect Data]:", data)
            return data[0]
            
        }catch(err){console.log("[Redirect Error]: Fetching shop")}

    }
export const fetchShops  = async(userObj) =>{
    console.log("[fetching shops] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/shop/owner`,{_id: id}, config)
                // console.log("[shopList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchshops]: ',err)}
            }

/*----------UPDATE------------*/
export const updateShop = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/shop/update`,
            {
                _id: obj._id,
                ...obj,
            },
            config
            );
            console.log('[updateshops]: data', data)
        return data
            
    }catch(err){console.log('[Updateshop Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteShop = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/shop/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteshopFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[Deleteshop Error]: ',err)}   
    }
/*--------------------
------Order Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createOrder = async (order,cart_id) =>{
    //  const untitledCount = () =>{
    //     let count = orders?.filter(a=>a?.name?.includes('untitled')).length
    //     return count}
    console.log('[API.createOrder] inital:',order)
      try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/order/createOrder`,
            {
                order,
                cart_id
                
            },
            config
            );
        return data

       }catch(err){console.log('[Createorder Error]: ',err)}   
   }
export const createCart = async (obj) =>{
    //  const untitledCount = () =>{
    //     let count = orders?.filter(a=>a?.name?.includes('untitled')).length
    //     return count}
    console.log('[API.createCart] inital: ',obj)
      try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/order/createCart`,
            {
                ...obj,
                
            },
            config
            );

            console.log('[CreateCart] data: ', data)
        return data

       }catch(err){console.log('[CreateCart Error]: ',err)}   
   }

   
    /*----------READ------------*/
  export const cartById =  async (cart_id) => {
        // console.log('order_id: ', order_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const  {data}  = await axios.post(`${ENDPOINT[0]}/order/checkCart/`,{cart_id},config)
            console.log("[Redirect Data]:", data)
            return data
            
        }catch(err){console.log("[Redirect Error]: Fetching order")}

    }
  export const fetchOrder =  async (order_id) => {
        // console.log('order_id: ', order_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const { data } = await axios.post(`${ENDPOINT[0]}/order/id/`,{_id:order_id},config)
            console.log("[Redirect Data]:", data)
            return data
            
        }catch(err){console.log("[Redirect Error]: Fetching order")}

    }
export const fetchOrders  = async(shop) =>{
    console.log("[fetching orders] for:", shop?._id)
    try{
                const id = shop?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${shop?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/order/orders`,{_id: id}, config)
                // console.log("[orderList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchorders]: ',err)}
            }

/*----------UPDATE------------*/
export const updateOrder = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/order/updateOrder`,
            {
                _id: obj._id,
                ...obj,
            },
            config
            );
            console.log('[updateorders]: data', data)
        return data
            
    }catch(err){console.log('[Updateorder Error]: ',err)}   
    }

export const fetchCarts  = async(shop) =>{
    console.log("[fetching cartss] for:", shop?._id)
    try{
                const id = shop?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${shop?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/order/carts`,{_id: id}, config)
                // console.log("[orderList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchCarts]: ',err)}
            }

export const updateCart = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/order/updateCart`,
            {
                _id: obj?._id,
                ...obj,
            },
            config
            );
            console.log('[updateCart]: data', data)
        return data
            
    }catch(err){console.log('[Updateorder Error]: ',err)}   
    }

export  const sendCart = async (cart,shop) =>{
    try{
             const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const tracks =[]
        if (cart?.type === 'music'){
            // const items = cart?.items
          cart?.items?.forEach((item)=>{
              if(item?.tracks?.length > 0){
                      tracks.push(item?.tracks)
                  }else{tracks.push(item)}
          }
        )  
        console.log('sendCart Tracks:', tracks)
        }

        // const { data } = await axios.put(
        //     `${ENDPOINT[0]}/order/sendCart`,
        //     {
        //         _id: cart?._id,
        //         cart: {...cart},
        //         items: cart?.items,
        //         tracks: tracks?.length > 0 ? tracks: [],
        //         shop: {...shop}
        //     },
        //     config
        //     );
        //     if(data){

        //         console.log('[sent cart]: data', data)
        //     return data
        //     }
            
    }catch(err){console.log(err)}

}

/*----------DESTROY------------*/
export const deleteOrder = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/order/delete`,
                {_id: obj?._id,
                    ...obj
                },
                config
                );
              console.log('[deleteorderFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[Deleteorder Error]: ',err)}   
    }
export const deleteOrderAsset = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/order/assetDelete`,
                {pic_public_id: obj?.pic_public_id
                },
                config
                );
              console.log('[deleteorderFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[Deleteorder Error]: ',err)}   
    }
export const deleteOrderPrintfile = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/order/printFileDelete`,
                {printFile_public_id: obj?.printFile_public_id
                },
                config
                );
              console.log('[deleteorderFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[Deleteorder Error]: ',err)}   
    }
/*--------------------
------Item Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createItem = async (obj,userID,items) =>{
     const untitledCount = () =>{
        let count = items?.filter(a=>a?.name.includes('untitled')).length
        return count}
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/item/create`,
            {
                ...obj,
                owner: userID,
                name: obj?.name ? obj?.name :'untitled item: #' + untitledCount() 
            },
            config
            );
        return data

       }catch(err){console.log('[Createitem Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchItem =  async (item_id) => {
        // console.log('item_id: ', item_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const { data } = await axios.post(`${ENDPOINT[0]}/item/itemId/`,{_id:item_id},config)
            console.log("[Redirect Data]:", data)
            return data[0]
            
        }catch(err){console.log("[Redirect Error]: Fetching item")}

    }
export const fetchItems  = async(userObj) =>{
    console.log("[fetching items] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/item/id`,{_id: id}, config)
                // console.log("[itemList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchiItems]: ',err)}
            }

/*----------UPDATE------------*/
export const updateItem = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/item/update`,
            {
                _id: obj._id,
                ...obj,
            },
            config
            );
            console.log('[updateItems]: data', data)
        return data
            
    }catch(err){console.log('[UpdateItem Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteItem = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/item/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteItemFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteItem Error]: ',err)}   
    }
/*--------------------
------Listener Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createListen = async (listener) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };

        const { data } = await axios.put(
            `${ENDPOINT[0]}/listener/updateListens`,
            {...listener},
            config
            );
        return data

       }catch(err){console.log('[Createlisten Error]: ',err)}   
   }

export const createListener = async (email,sharedById) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       
        const { data } = await axios.post(
            `${ENDPOINT[0]}/listener/create`,
            {email:email?.toLowerCase(),
             sharedById : sharedById
            },
            config
            );
            // console.log('[createListener] data', data)
        return data

       }catch(err){console.log('[Createlistener Error]: ',err)}   
   }

export const verifyListener = async (listener,pin,sharedById) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
    
        const { data } = await axios.post(
            `${ENDPOINT[0]}/listener/verify`,
            {listener,pin,sharedById},
            config
            );
            // console.log('[createListener] data', data)
        return data

       }catch(err){console.log('[Createlistener Error]: ',err)}   
   }

export const shareMusic = async (email,share) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       
        const { data } = await axios.post(
            `${ENDPOINT[0]}/listener/share`,
            {email,share},
            config
            );
            // console.log('[createListener] data', data)
        return data

       }catch(err){console.log('[Listener Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchListener =  async (email,sharedById) => {
        // console.log('listener_email: ', listener_email)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const data  = await axios.put(`${ENDPOINT[0]}/listener/email/`,
                {email:email,sharedById:sharedById},config)
            console.log("[fetchListener Data]:", data.data)
            return data.data
            
        }catch(err){console.log("[fetchListener Error]: Fetching listener")}

    }
export const fetchListenerById = async(userObj) =>{
    console.log("[fetching listeners] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/listener/id`,{_id: id}, config)
                // console.log("[listenerList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchiListeners]: ',err)}
            }

/*----------UPDATE------------*/
export const updateListenerName = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/listener/updateName`,
            {
                email: obj.email,
                preferredName: obj.preferredName
            },
            config
            );
            // console.log('[updateListeners]: data', data)
        return data
            
    }catch(err){console.log('[UpdateListener Error]: ',err)}   
    }

/*----------UPDATE NAME------------*/
export const updateListener = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/listener/updateListener`,
            {
                ...obj
            },
            config
            );
            // console.log('[updateListeners]: data', data)
        return data
            
    }catch(err){console.log('[UpdateListener Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteListener = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/listener/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteListener FromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteListener Error]: ',err)}   
    }
/*--------------------
------Album Calls-------
----------------------*/
 
/*----------CREATE------------*/

export const createAlbum = async (email) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       
        const { data } = await axios.post(
            `${ENDPOINT[0]}/listener/create`,
            {email},
            config
            );
            console.log('[createAlbum] data', data)
        return data

       }catch(err){console.log('[Createlistener Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchAlbums =  async (artist_id) => {
        // console.log('listener_email: ', listener_email)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const data  = await axios.get(`${ENDPOINT[0]}/albums/`,{ id:artist_id},config)
            console.log("[Redirect Data]:", data.data)
            return data.data
            
        }catch(err){console.log("[Redirect Error]: Fetching listener")}

    }
    export const fetchAlbumById = async(userObj) =>{
         console.log("[fetching listeners] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/listener/id`,{_id: id}, config)
                // console.log("[listenerList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchiAlbums]: ',err)}
            }

/*----------UPDATE------------*/
/*----------UPDATE ------------*/
export const updateAlbum = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/album/updateAlbum`,
            {
                ...obj
            },
            config
            );
            console.log('[updateAlbums]: data', data)
        return data
            
    }catch(err){console.log('[UpdateAlbum Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteAlbum = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/listener/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteAlbum FromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteAlbum Error]: ',err)}   
    }
/*--------------------
------Track Calls-------
----------------------*/
 
/*----------CREATE------------*/

export const createTrack = async (email) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       
        const { data } = await axios.post(
            `${ENDPOINT[0]}/tracks/create`,
            {email},
            config
            );
            console.log('[createTrack] data', data)
        return data

       }catch(err){console.log('[Createlistener Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchTracks =  async (email) => {
        // console.log('listener_email: ', listener_email)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            
            const data  = await axios.get(`${ENDPOINT[0]}/tracks/`,{},config)
            console.log("[Redirect Data]:", data.data)
            return data.data
            
        }catch(err){console.log("[Redirect Error]: Fetching listener")}

    }
    export const fetchTrackById = async(userObj) =>{
         console.log("[fetching listeners] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/listener/id`,{_id: id}, config)
                // console.log("[listenerList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchiTracks]: ',err)}
            }

/*----------UPDATE------------*/

export const saveComment = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/tracks/comment`,
            {
                ...obj
            },
            config
            );
            console.log('[updateTracks]: data', data)
        return data
            
    }catch(err){console.log('[UpdateTrack Error]: ',err)}   
    }
/*----------UPDATE ------------*/
export const updateTrack = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/tracks/update`,
            {
                ...obj
            },
            config
            );
            console.log('[updateTracks]: data', data)
        return data
            
    }catch(err){console.log('[UpdateTrack Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteTrack = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/listener/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteTrack FromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteTrack Error]: ',err)}   
    }
/*--------------------
------Artist Calls-------
----------------------*/
 
/*----------CREATE------------*/

export const createArtist = async (email) =>{
     
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       
        const { data } = await axios.post(
            `${ENDPOINT[0]}/artist/create`,
            {email},
            config
            );
            console.log('[createArtist] data', data)
        return data

       }catch(err){console.log('[Createlistener Error]: ',err)}   
   }

   
    /*----------READ------------*/
    export const fetchArtist = async(id) =>{
         console.log("[fetching artist info] for:", id)
    try{
                // const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/artist/id`,{_id:id}, config)
                // console.log("[artistList] data: ", data?.data)
                if(data){

                    return data?.data
                }else{
                    return null
                }
                
                
            }catch(err){console.log('[fetchiArtists]: ',err)}
            }

/*----------UPDATE------------*/


/*----------UPDATE ------------*/
export const updateArtist = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/artist/update`,
            {
                ...obj
            },
            config
            );
            console.log('[updateArtists]: data', data)
        return data
            
    }catch(err){console.log('[UpdateArtist Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deleteArtist = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/artist/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteArtist FromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteArtist Error]: ',err)}   
    }

/*--------------------
------Misc. Calls-------
----------------------*/

export const savePic = (pics) => {
    let pic =''
    if (pics === undefined) {
    
      return;
    } 
  
  if (pics.type === "image/jpeg" || pics.type === "image/png") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "QauRa");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          pic = data?.url.toString();
          console.log("data?.url.toString() :",data?.url.toString());
          return pic
      
        })
        .catch((err) => {
          console.log(err);
       
        });
    } 
    // else {
    
    //   return;
    // }
  }
// export const deletePic = async (pic) => {
//     const key = '735485743319177'
//     const secret = 'OVZviU8GjGGgtbbHOiShrCtwZlU'
//     // var cloudinary = require('cloudinary').v2;
    

// // Change cloud name, API Key, and API Secret below

// // cloudinary.config({ 
// const cloudinary = new Cloudinary({
//   cloudName: 'dzxzdsnha', 
//   apiKey: key, 
//   apiSecret: secret 
// });

// // Change 'sample' to any public ID of your choice

// cloudinary.destroy(pic, function(result) { console.log(result) });
  
//   }
// export const deletePic = async (pic) => {    
//     const key = '735485743319177'
//    const response = await fetch(`https://api.cloudinary.com/v1_1/dzxzdsnha/delete/${pic}`, {
//       method: 'DELETE',
//       headers: {
//         'Authorization': `Bearer ${key}`,
//       },
//     });

//     if (response.ok) {
//     //   setIsLoading(false);
//       console.log('Image deleted successfully!');
//     } else {
//     //   setIsLoading(false);
//       console.error('Error deleting image:', response.statusText);
//     }
  
//   }


   export const getGeoData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log('user geo res.data: ', res?.data)
        // setGeoData({...res?.data})
        // console.log('user geodata: ', geoData)
        // if(res?.data){
        //     collectScanData(res?.data)
        // }
        return res.data
    }

  /*--------------------
------Admin Calls-----
----------------------*/

/*----------Read------------*/
 export const searchUser = async (user,query) => {
 if (!query) {
      return;
    }

    try {
     
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const data  = await axios.get(`${ENDPOINT[0]}/user?search=${query}`, config);
      console.log(data);
      return data.data
    } catch (error) {
     console.log("Error Occured!",error)
    }
  };
  /*--------------------
------Stream Calls-----
----------------------*/

/*----------Read------------*/
 export const fetchRTPConfig = async () => {
 

    try {
     
      const config = {
        // headers: {
        //   Authorization: `Bearer ${user?.token}`,
        // },
      };
      const {data}  = await axios.get(`${ENDPOINT[0]}/rtp-config`);
    //   console.log('[fetchRTPConfig] data:',data);
      return data
    } catch (error) {
     console.log("Error Occured!",error)
    }
  };

  /*--------------------
------Payment Calls-----
----------------------*/

/*----------Read------------*/
 export const fetchPayKeys = async () => {
 

    try {
     
      const config = {
        // headers: {
        //   Authorization: `Bearer ${user?.token}`,
        // },
      };
      const data  = await axios.get(`${ENDPOINT[0]}/payment/config`);
      console.log('fetchPayKeys',data);
      return data
    } catch (error) {
     console.log("Error Occured!",error)
    }
  };


  export const createPaymentIntent = async (items,cart_id) => {
    try {    
      const config = {
        headers: {
        //   Authorization: `Bearer ${user?.token}`,
        },
      };
      const data  = await axios.post(`${ENDPOINT[0]}/payment/createIntent`,{items,cart_id});
      console.log('createPayment',data);
      return data?.data
    } catch (error) {
     console.log("Error Occured!",error)
    }
  };

  
// }
// export default APICalls