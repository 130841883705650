import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';
import EnvRoute from '../../../middleware/envRoute';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as API from '../../../utils/APICalls';

const ENDPOINT = EnvRoute('prod');

const SingleWebRTCComponent = ({ listener, artist, hostList }) => {
  const isHost = hostList?.includes(listener?._id);
  const [isConnected, setIsConnected] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [isProducerReady, setIsProducerReady] = useState(false);
  const socketRef = useRef();
  const deviceRef = useRef();
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const producerTransportRef = useRef();
  const consumerTransportRef = useRef();
  const [videoDevices, setVideoDevices] = useState([]);
  const [routerRTPCaps, setRouterRTPCaps] = useState(null);

  useEffect(() => {
    if (artist?.stream?.isStreaming) {
      connectWithRetry();
      getVideoDevices();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [artist?.stream?.isStreaming]);

  const connectWithRetry = async () => {
    try {
      const { routerRtpCapabilities } = await API.fetchRTPConfig();
      setRouterRTPCaps(routerRtpCapabilities);

      const SOCKET_ENDPOINT = ENDPOINT[0].slice(0, -4);
      socketRef.current = io(`${SOCKET_ENDPOINT}/chat`, {
        transports: ['websocket'],
        upgrade: false,
      });

      socketRef.current.on('connect', () => {
        console.log('Connected to server');
        setIsConnected(true);
      });

      socketRef.current.on('disconnect', () => {
        console.log('Disconnected from server');
        setIsJoined(false);
        setIsConnected(false);
      });
    } catch (err) {
      console.error('Connection error:', err);
    }
  };

  const getVideoDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setVideoDevices(videoDevices);
    } catch (error) {
      console.error('Error getting video devices:', error);
    }
  };

  const joinRoom = async (roomId, userId) => {
    try {
      setIsJoining(true);
      deviceRef.current = new mediasoupClient.Device();
      await deviceRef.current.load({ routerRtpCapabilities: routerRTPCaps });

      socketRef.current.emit('join-room', roomId, userId, isHost, {
      rtpCapabilities: deviceRef.current.rtpCapabilities
    });

      if (isHost) {
        await setupProducerTransport(roomId, userId);
        // await waitForProducerReady();
      } else {
        await setupConsumerTransport(roomId, userId);
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[currentCameraIndex]?.deviceId },
        audio: true,
      });

      if (stream) {
        if (isHost && localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
        if (producerTransportRef.current) {
          await producerTransportRef.current.produce({ 
            track: stream.getVideoTracks()[0],
            kind:'video'
           });
          await producerTransportRef.current.produce({ 
            track: stream.getAudioTracks()[0],
          kind:'audio'
         });
        }
      }

      setIsJoining(false);
      setIsJoined(true);
    } catch (error) {
      console.error('Error joining room:', error);
      setIsJoining(false);
    }
  };

  const setupProducerTransport = async (roomId, userId) => {
    socketRef.current.on('producer-transport-created', async (params) => {
      producerTransportRef.current = await deviceRef.current.createSendTransport(params.params);
      console.log('[setupProducerTransport] producer-transport-created:', producerTransportRef.current)
      
    producerTransportRef.current.on('connect', ({ dtlsParameters }, callback, errback) => {
        socketRef.current.emit('connect-producer-transport', { dtlsParameters, roomId, userId }, callback);
      });

      producerTransportRef.current.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { producerId } = await new Promise((resolve) => {
            socketRef.current.emit('produce', { roomId, userId, kind, rtpParameters }, resolve);
          });
          callback({ id: producerId });

           if (kind === 'video') {
            setIsProducerReady(true);
          }
        } catch (err) {
          errback(err);
        }
      });
    });
  };

  const waitForProducerReady = () => {
  return new Promise((resolve) => {
    const checkReady = () => {
      if (isProducerReady) {
        resolve();
      } else {
        setTimeout(checkReady, 100);
      }
    };
    checkReady();
  });
};


// const consumeStream = async (producerId, kind, rtpParameters) => {
//  try {
//    console.log('Kind:', kind)
//    if (typeof kind !== 'string' || !['video', 'audio'].includes(kind)) {
//          throw new Error(`Invalid media kind: ${kind}`);
//        }
//    const consumer = await consumerTransportRef.current.consume({
//       id: consumerId,
//      producerId,
//      kind,
//      rtpParameters
//    });
//      const stream = new MediaStream([consumer?.track]);

//      console.log('Remote stream obtained:', stream?.getTracks().map(track => ({
//          kind: track.kind,
//          enabled: track.enabled,
//          readyState: track.readyState
//        })));

//      if (kind === 'video' && remoteVideoRef.current) {
//        remoteVideoRef.current.srcObject = stream;
//      } else if (kind === 'audio') {
//        const audioElement = new Audio();
//        audioElement.srcObject = stream;
//        audioElement.play();
//      }

//      socketRef.current.emit('consumer-resume', { consumerId: consumer?.id });
//    } catch (error) {
//      console.error('Error consuming stream:', error);
//    }
//  };
//  console.log('Kind:', kind)
//  if (typeof kind !== 'string' || !['video', 'audio'].includes(kind)) {
//        throw new Error(`Invalid media kind: ${kind}`);
//      }
const consumeStream = async (producerId,roomId, kind, rtpParameters) => {
 try {
    const  consumerParams  = await new Promise((resolve) => {
      // socketRef.current.emit('consume', { producerId}, resolve);
      socketRef.current.emit('consume', { producerId, roomId, userId: listener._id }, resolve);
    });
       console.log('consumerParams:',consumerParams)
     if (!consumerParams?.id || !consumerParams.producerId) {
      throw new Error('Invalid consumer parameters from server');
    }

   const consumer = await consumerTransportRef.current.consume(consumerParams);
     const stream = new MediaStream([consumer?.track]);

     console.log('Remote stream obtained:', stream?.getTracks().map(track => ({
         kind: track.kind,
         enabled: track.enabled,
         readyState: track.readyState
       })));

     if (kind === 'video' && remoteVideoRef.current) {
       remoteVideoRef.current.srcObject = stream;
     } else if (kind === 'audio') {
       const audioElement = new Audio();
       audioElement.srcObject = stream;
       audioElement.play();
     }

     socketRef.current.emit('consumer-resume', { consumerId: consumer?.id });
   } catch (error) {
     console.error('Error consuming stream:', error);
   }
 };

const setupConsumerTransport = async (roomId, userId) => {
  socketRef.current.on('consumer-transport-created', async (params) => {
    // console.log('consumer-transport-created params.params:', params?.params);
    //  console.log('Received transport params:', JSON.stringify(params, null, 2));
    consumerTransportRef.current = await deviceRef.current.createRecvTransport({...params?.params});
    
    console.log('Consumer transport created:', consumerTransportRef.current);
    
    // Set up event handlers here
    setupConsumerTransportEventHandlers(roomId, userId);
    
  });
};

  const setupConsumerTransportEventHandlers = (roomId, userId) => {
  if (!consumerTransportRef.current) return;

  consumerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
    console.log('Consumer transport connect event fired');
    try {
      await socketRef.current.emit('connect-consumer-transport', { dtlsParameters, roomId, userId });
      callback();
    } catch (error) {
      errback(error);
    }
  });

  consumerTransportRef.current.on('connectionstatechange', (state) => {
    console.log('Consumer transport connection state changed to', state);
  });
  
  // Other event handlers...
  
  // socketRef.current.on('new-producer', async ({ producerId,id, kind, rtpParameters,}) => {
  //   console.log('[new-producer] producerId:', producerId);
  //   console.log('[new-producer] kind:', kind);
  //   await consumeStream(producerId,id, kind, rtpParameters);
  // });

    socketRef.current.on('new-producer', async ({ producerId }) => {
      try {
        // Request server to create consumer
        const {producerId, roomId,id, kind, rtpParameters } = await new Promise(resolve => {
          socketRef.current.emit('consume', 
            { producerId,roomId,userId },
            resolve
          );
        });

        await consumeStream(producerId, roomId, kind, rtpParameters);
      } catch (error) {
        console.error('Consumption failed:', error);
      }
    });

    
    // consumerTransportRef.current.on('existing-producers', async (producers) => {
    socketRef.current.on('existing-producers', async (producers) => {
        console.log('[existing-producer] producerId:', producers);
        for (const { producerId,roomId,id, kind, rtpParameters } of producers) {
          console.log('producer kind:', kind)
          await consumeStream(producerId,roomId,kind, rtpParameters);
        }
      });
};


  const leaveRoom = (roomId, userId) => {
    socketRef.current.emit('leave-room', { roomId, userId });
    if (producerTransportRef.current) {
      producerTransportRef.current.close();
    }
    if (consumerTransportRef.current) {
      consumerTransportRef.current.close();
    }
    setIsJoined(false);
  };

  const toggleCamera = async () => {
    if (!isHost) return;
    const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    setCurrentCameraIndex(newIndex);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[newIndex].deviceId },
        audio: true
      });
      const videoTrack = stream.getVideoTracks()[0];
      const sender = producerTransportRef.current.getSenders().find(s => s.track.kind === 'video');
      if (sender) {
        await sender.replaceTrack(videoTrack);
      }
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error toggling camera:', error);
    }
  };

  return (
    <Stack sx={{ position: 'relative', gap: '0.5rem', padding: '0.5rem 0' }}>
      { isHost && (
        <video
          ref={localVideoRef}
          autoPlay
          playsInline
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            maxWidth: '100vw',
            height: '100vh',
            minHeight: '600px',
            border: '1px solid #00FF00',
            borderRadius: '5px'
          }}
        />
      )}
      {isConnected && isJoined && !isHost && (
        <video
          ref={remoteVideoRef}
          autoPlay
          playsInline
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            maxWidth: '100vw',
            height: '100vh',
            minHeight: '600px',
            border: '1px solid #00FF00',
            borderRadius: '5px'
          }}
        />
      )}
      
      {artist?.stream?.isStreaming && (
        <Typography sx={{ color: isConnected ? '#00FF00' : 'orange' }}>
          {isConnected && isHost ? 'Hosting' : isConnected && !isHost ? 'Connected' : 'Disconnected'} as <b>{listener?.preferredName || 'listener' + listener?._id}</b>
        </Typography>
      )}
      {isConnected && (
        <Box sx={{ display: "flex", margin: 'auto', gap: '0.5rem' }}>
          {!isJoined && (
            <Button disabled={isJoining} variant='outlined' onClick={() => joinRoom('room1', listener?._id)}>
              Join{isJoining ? 'ing' : ''} Room
            </Button>
          )}
          {isJoined && (
            <Button variant='outlined' onClick={() => leaveRoom('room1', listener?._id)}>
              Leave Room
            </Button>
          )}
          {(isHost && videoDevices?.length > 1) && (
            <Button variant='outlined' onClick={toggleCamera}>Switch Camera</Button>
          )}
        </Box>
      )}
      {isHost && (
        <Typography sx={{ color: isProducerReady ? '#00FF00' : 'orange' }}>
          Producer {isProducerReady ? 'Ready' : 'Initializing'}
        </Typography>
      )}
    </Stack>
  );
};

export default SingleWebRTCComponent;
