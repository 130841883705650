import React from 'react'

function EnvRoute(env) {
// env = 'dev'          
  if (env === 'dev'){ return ["http://localhost:5005/api","http://localhost:3000/"]}
  if (env === 'stage'){ return ["https://localhost:5003/api","https://localhost:5002/api"]
  }else{ return ["https://qaura-api.onrender.com/api","https://qaura.pro/"] }

}

export default EnvRoute


