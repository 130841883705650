import { Box, Container, Stack, Typography } from '@mui/material';
import React,{useEffect, useState, useRef, useContext} from 'react'
import useWindowDimensions from "../hooks/windowDimensions";
import CloseButton from '../components/CloseButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Copyright from './Copyright';

export const vars={
    colors:{

        accent_color_1: 'rgba(0,0,0,1)',
        accent_color_2: 'rgba(255,255,255,1)',
        text_color_1: 'rgba(0,0,0,1)',
        box_shadow_01: 'rgba(0,0,0,0.4)',
        text_color_2: 'rgba(255,255,255,1)',
        term_green: "#00FF00",
        closeButton:'#f66969',
        term_header: 'rgb(246, 246, 246)',
        term_headerText: 'grey',
        projectTitle: 'magenta',
    },
    fonts: {
         main_app: 'Gilroy',
        term_head:'',
        term_title:'',
        term_text:'Monaco',
        term_btn_cls:'futura bold'
    }
    }

const ErrorPage = () => {
    const parentRef   = useRef(null);
    const childrenRef = useRef(null);
     const [parentDim,setParentDim ] = useState()
     const [ hideCopy ,setHideCopy ] = useState()
  const { height, width } = useWindowDimensions();

    const [ blink, setBlink ] = useState(false)
    useEffect ( () => {
        
        if(parentRef.current){
            
            let parentHeight = parentRef.current.offsetHeight;
            let parentWidth  = parentRef.current.offsetWidth;
            console.log('Parent H | W:', parentHeight, parentWidth)
            setParentDim({
                width: parentWidth,
                height: parentHeight
            })
            
        }
        
        if(childrenRef.current){
            
            let childrenHeight = childrenRef.current.offsetHeight;
            let childrenWidth  = childrenRef.current.offsetWidth;
            console.log('Parent H | W:', childrenHeight, childrenWidth)
            
        }
        
    }, [parentRef, childrenRef]);


      function sleep(time){
                return new Promise(resolve => {
                    setTimeout(resolve, time)
                    // setAnimationFrame instread for event loop purposes
                })  
            }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    const blinkFunc = async () =>{
        let blinkTimes =  Math.floor(Math.random() * 1) + 2
        setBlink(false);
        for( let i =0; i < blinkTimes; i++ ){

            setBlink(true);
             await sleep(Math.floor(Math.random() * 1000) + 500)
            setBlink(false);
        }
    }
      useEffect(() => {
          const blinkInc = !blink? 1 : getRandomInt(1, 2);
        if(blink){
            sleep(1000)
        }
          const intervalId = setInterval(() => {
               blinkFunc() 
        }, Math.floor(Math.random() * 1000) + ( 5000 * blinkInc )); // Random interval between 1 to 6 seconds

        return () => {
            
            clearInterval(intervalId);
        };
    }, []);

  return(
      <Container sx={{position:'relative',justifyContent:'center',padding:'0.5rem',//borderRadius:'100px 100px',
        background:'#00FFFF', backgroundImage:`linear-gradient(to top, #ffffff, #ffffff, #ffffff, #ffffff00, #ffffff00)`}}>
    
      
            <Box sx={{display:'flex', flexDirection:'column',
                alignContent:'center', alignItems:'center',
                position: 'relative',height:'100%',
                margin:'auto',
                width:'350px',
                maxWidth:'350px',}}>
                    <div className="cloud-div2">  
                        <img src="/assets/Cloud_01.png" alt ="cloud"/>
                        </div> 

            <div className="" 
                style={{
                    position:'absolute',
                    top:0,
                    left:0,
                    width:'350px',
                    height:'350px',
                    zIndex:0
                    }}>  
                    <img 
                        style={{
                        
                        width:'350px',
                        height:'auto',
                    }} src="/assets/Porthole_Plain.png" alt ="porthole"/>
                </div>
                <Stack sx={{position: 'absolute',height:'100%',
                    transform: 'translate(0%,24.5%)',
                    margin:'auto',
                    margin:'auto',
                    width:'230px',
                    maxWidth:'230px',
                    height:'230px',
                    minHeight:'230px',
                    justifyContent:'center',
                    borderRadius:'100%',
                    border:'5px solid #000',
                    backgroundColor:'rgba(53, 54, 71,0.5)',
                    overflow:'hidden', //overflowY:'scroll',
                    }}>
        
            <Box sx={{position:'absolute',bottom:0,left:0,//overflow:'hidden',
                    display:'flex',//flexDirection:'column',
                    width: '250px',height:'250px',minWidth:'250px',
                    //alignContent:'center',//margin:'auto',
                }}>  
                
                    <Box 
                    sx={{position:'relative' ,overflow:'hidden',display:'flex',
                    width: '50%',margin:'auto', 
                    alignContent:'center',  zIndex:2}}
                    ref={parentRef}
                    id="portrait"
                
                    >
            
                        {/* <div className="cloud-div2">  
                            <img src="/assets/Cloud_01.png" alt ="cloud"/>
                            </div>  */}
                    <Box component={'img'}
                        onMouseEnter={() => setBlink(true)}
                        onMouseLeave={() => setBlink(false)}
                        src={!blink?'/assets/Self-Portrait-Plain-NoGlasses.png':'/assets/Self-Portrait-Plain-NoGlasses-Closed.png'}
                            sx={{position:'relative',top:0,left:0,//width:'100%',
                                // backgroundImage: `url("/assets/Self-Portrait-Plain-NoGlasses.png")`,
                                // backgroundAttachment:'fixed',
                                // backgroundSize:'cover',
                                // // backgroundSize: "contain",
                                height:'100%',
                                width:'100%',  zIndex:2
                            }}
                        alt="AJ self-portrait"
                        
                    
                        >
                        </Box>
                        {/* <div ref={childrenRef} style={{position:'absolute', top: 0}}>
                            <Three  width={parentDim?.width} height={parentDim?.height}/>
                            </div> */}
                        {/* <div style={{position: 'absolute',top:0,left:0,border:'1px solid red', width:'100%',height:'100%'}}>

    </div> */}
                </Box>
                    <Box component={'img'}
                    src={'/assets/MedMan_Flat.png'}
                    className='small-image'
                    // sx={{ zIndex:9999}}
                    >
                </Box>
                    <Box 
                className='small-image center'
                >
                        <div className="sphere-wrap center small-image"> 
                    <div className="login-top sun">
                    <img src="/assets/Sun_01.png" alt ="sun"/>
                    </div>
                    <div className="login-bottom moon">
                    
                        <img src="/assets/Moon_01.png" alt ="moon"/>
                    </div>
                </div> 
                </Box>
                </Box>

                    <div className="rot-bg small-image">
                <div className="sphere-wrap  center"> 
                    <div className="login-top sun">
                    <img src="/assets/Sun_01.png" alt ="sun"/>
                    </div>
                    <div className="login-bottom moon">
                    
                        <img src="/assets/Moon_01.png" alt ="moon"/>
                    </div>
                </div>
                <div className="cloud-div" style={{zIndex:blink?0:99999}}>  
                    <img src="/assets/Cloud_01.png" alt ="cloud"/>
                </div>
                {/* <div className="cloud-div2">  
                    <img src="/assets/Cloud_01.png" alt ="cloud"/>
                </div> */}
                </div>
            </Stack>
            </Box>
        <Stack sx={{position:'absolute', top:'40vh',left:'50%',transform:'translate(-50%,0%)',margin:'auto', width:'90%'}}>
                <Stack sx={{border:'5px solid #000', boxShadow:'0 0 8px rgba(0,0,0,0.5)',
                    backgroundColor:'rgba(255,255,255, 1)',borderRadius:'20px 20px 10px 10px',padding:'0.5rem'}}>

            //!--------------------------!// 
            <Typography sx={{fontFamily:"Gilroy, sans-serif",fontSize:'2rem',fontWeight:'bold',textAlign:'center'}}>You found me...</Typography>
            <Typography sx={{fontFamily:"Gilroy, sans-serif",fontSize:'1.5rem',fontWeight:'bold',textAlign:'center'}}>( But you're not supposed to be here. )</Typography>
            //!--------------------------!//


                         <Box sx={{ position:'relative', flex:1, backgroundColor: vars?.colors?.accent_color_1, 
                    fontSize:'0.75rem',minHeight:'275px',height:'30vh', maxWidth: '100%', 
                    overflow:'hidden', borderRadius:'5px',
                    boxShadow:'0px -1px 10px 5px rgba(0, 0, 0, 0.2)',
                    // zIndex:50,
                    }}>
                
                <div style={{width:"100%", height:'1.25rem',
                    backgroundColor: vars?.colors?.term_header, 
                    color: vars?.colors?.term_headerText,
                    borderRadius:'5px 5px 0px 0px',
                    textAlign:'center',
                    alignItems:'center',
                   
                    }}>
                        <div className="term-button-cls" id="term-button-cls"onClick={()=>{
                            // toggleMenu()

                        }}><span id="x-btn-text">x</span></div>
                        <Typography sx={{fontSize:'0.8rem',fontWeight:'bold'}}>
                        - Projects
                        </Typography>
                        </div>
                        
                     {/* <Typography sx={{fontSize:'0.7rem',color:vars?.colors?.projectTitle,fontFamily:vars.fonts.term_title,marginLeft:'0.5rem'}}>RexEntropy@ ~/Projects/error404 %  
                   <span style={{color:'#00FF00'}}>&nbsp; &nbsp;ls</span>
                    </Typography> */}
               
                       <Typography className='blink'
                        sx={{display:'flex',color:'#00FF00',fontSize:'5rem', justifyContent:'center',
                            textAlign:'center', alignContent:'center', alignItems:'center',
                            alignSelf:'center',margin:'auto',position:'relative',
                            textShadow:' #000 0px 0px 10px,#00FF00 0px 0px 30px',
                            boxShadow:'0 0 12px rgba(0,255,0, 0.7), inset 0 0 7px rgba( 0, 255,0,0.5)',
                            border:'5px ridge #00FF00', borderRadius:'50px', padding:'0.5rem', width:'200px',height:'5rem',
                            fontFamily:'Gilroy, sans-serif', fontWeight:'bold',margin:'1.5rem auto' }}
                          
                            >
                        404
                        
                        </Typography>
                
                       <Typography className='blink-2'
                        sx={{fontFamily:"Gilroy, sans-serif",fontSize:'5rem',fontWeight:'bold',textAlign:'center'}}>
                        </Typography>
                      <div style={{display: 'flex',flexDirection:'column',gap:'0.125rem',
                            marginTop: '-0.25rem'}}>
                        <span style={{display:'flex', flexDirection:'row',width:'100%'}}>

                            <span style={{fontSize:'0.75rem',color:vars?.colors?.projectTitle,
                                fontFamily:vars.fonts.term_title,marginLeft:'0.5rem',display:'flex'}}>
                                RexEntropy@ ~/Projects/error404 % 
                                </span>

                                <span style={{fontSize:'0.65rem',color:vars?.colors?.term_green,
                                fontFamily:vars.fonts.term_text, marginLeft:'0.5rem'}}>
                                 cd Home
                             </span>
                        </span>
                        <span style={{display:'flex', flexDirection:'row',width:'100%'}}>

                            <span style={{fontSize:'0.75rem',color:vars?.colors?.projectTitle,
                                fontFamily:vars.fonts.term_title,marginLeft:'0.5rem',display:'flex'}}>
                                RexEntropy@ ~/Projects/error404 % 
                                </span>

                                <span style={{fontSize:'0.65rem',color:vars?.colors?.term_green,
                                fontFamily:vars.fonts.term_text, marginLeft:'0.5rem'}}>
                                 ls
                             </span>
                        </span>
                                    <Typography className='blink'
                                     sx={{display:'flex',color:'#00FF00',fontSize:'1.5rem', justifyContent:'center',
                                         alignSelf:'center',margin:'auto',position:'relative',
                                         fontFamily:'Gilroy, sans-serif', fontWeight:'bold',marginTop:'1.5rem' }}                                       
                                         >
                        <div style={{marginBottom:'1rem'}} className='just-moving'><b>&#8623;</b></div>&nbsp;<u>Click to Return</u>&nbsp;<div className='just-moving'>
                            {/* &#8682; */}
                            <span sx={{transform:'scale(-1,1)'}}>&#8623;</span>
                            </div>
                        </Typography>

                     <Box className='blink-2'
                     sx={{display:'flex', flexDirection: width<= 700?'column':'row',//justifyContent:'center', 
                        padding:'0.5rem', margin:'auto',flexWrap:'no-wrap', 
                        boxShadow:'0 0 20px rgba(0,255,0, 0.7), inset 0 0 8px rgba( 0, 255,0,0.5)',backgroundColor:'#000',
                        justifyContent:'center', maxWidth:'600px',
                        marginBottom:'10rem',border:'5px ridge #00ff00', borderRadius:'20px',padding:'1rem',
                        alignContent:'center',alignItems:'center',width:'60%',minWidth:'fit-content',gap:'1rem'}}>
                    <span 
                    style={{
                        position:'relative',
                        cursor:'pointer',
                        // bottom:'-1rem',
                        // left:'25%',
                    //  transform:'translate(-10%,0)',
                        display:'flex',color:vars?.colors?.term_green ,
                        backgroundColor:'#000',
                         textShadow:' #000 0px 0px 10px,#00FF00 0px 0px 30px',
                        boxShadow:'0 0 15px rgba(0,255,0, 0.7), inset 0 0 4px rgba( 0, 255,0,0.5)',
                        border:`3px solid ${vars?.colors?.term_green}`, borderRadius:'25px',padding:'0.5rem' }}
                        onClick={()=>{
                        window.location.assign('/RexPlayer','_blank')
                        }}
                        className="blink-2"
                        >
                    <b style={{ position:'absolute',left:'2rem', top:'0.25rem',}}>
                        The 
                    </b>
                    <h1>RexPlayer</h1>
                    </span> 

                    <Box className="text-shadow-term-green blink-2"
                    onClick={()=>{window.location.assign('/partners/parallaxr','_blank')
                        }}
                    sx={{margin:'auto', alignItems:'center', display:'flex',width:'fit-content',cursor:'pointer',padding:'0.5rem',
                        borderRadius:'10px',border:'3px solid #00FF00', 
                    boxShadow:'0 0 10px rgba(0,255,0, 0.7), inset 0 0 5px rgba( 0, 255,0,0.7)',}}>
                        <img src={`https://res.cloudinary.com/dzxzdsnha/image/upload/v1738009757/Parallaxr_Shop_Logo_GRN_h1fx16.png`} className="text-shadow-term-green blink-2"
                            width='100px'style={{opacity:'1', alignSelf:'center',margin:'auto',height:'50px',width:'50px'}} />
                    </Box>
                    <h1  onClick={()=>{window.location.assign('/','_blank')}} style={{cursor:'pointer'}} className="text-shadow-term-green blink-2">QauRa</h1>
                    <Box className="text-shadow-term-green blink-2"
                    onClick={()=>{window.location.assign('https://rexentropy.com','_blank')
                        }}
                    sx={{margin:'auto', alignItems:'center', display:'flex',width:'fit-content',cursor:'pointer',padding:'0.5rem',
                        // borderRadius:'10px',border:'3px solid #00FF00', 
                    // boxShadow:'0 0 10px rgba(0,255,0, 0.7), inset 0 0 5px rgba( 0, 255,0,0.7)',
                    }}>
                        <img src={`/assets/Rex_Entropy_Logo_Flat_LG_GRN.png`} className="text-shadow-term-green blink-2"
                           style={{opacity:'1', alignSelf:'center',margin:'auto',height:'75px',}} />
                    </Box>
            </Box>
                       
                      </div>
                      
                    
           </Box>




                </Stack>
                {/* <Box sx={{display:'flex', flexDirection: width<= 700?'column':'row',//justifyContent:'center', 
                    padding:'0.5rem', margin:'auto',flexWrap:'no-wrap',
                        marginBottom:'10rem',
                    alignContent:'center',alignItems:'center',width:'90%',gap:'1rem'}}>
                    <span 
                    style={{
                        position:'relative',
                        cursor:'pointer',
                        // bottom:'-1rem',
                        // left:'25%',
                    //  transform:'translate(-10%,0)',
                        display:'flex',color:'#000' ,
                        backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                        width:'fit-content',
                    
                        boxShadow:'0 0 8px rgba(0,0,0, 0.7), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                        border:'3px solid #000', borderRadius:'30px',padding:'0.5rem' }}
                        onClick={()=>{
                        window.location.assign('/RexPlayer','_blank')
                        }}
                        className="blink-2"
                        >
                    <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                        The 
                    </b>
                    <h1>RexPlayer</h1>
                    </span> 

                    <Box className="blink-2"
                    onClick={()=>{window.location.assign('/partners/parallaxr','_blank')
                        }}
                    sx={{margin:'auto', alignItems:'center', display:'flex',width:'100%',cursor:'pointer',}}>
                        <img src={`https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg`}
                            width='100px'style={{opacity:'1', alignSelf:'center',margin:'auto'}} />
                    </Box>
                    <h1  onClick={()=>{window.location.assign('/','_blank')}} style={{cursor:'pointer'}}className="text-shadow-02 blink-2">QauRa</h1>
            </Box> */}
        </Stack>

              <Box sx={{display:'flex',
                    flexDirection:'column',
                    position:'absolute',
                    bottom:'0',
                    left:'50%',
                    transform:'translate(-50%,20%)',
                    width:'60%',
                    height:hideCopy? '0':'8.5rem',
               
                    }}>

                    <Box sx={{position:'relative',alignSelf:'start',width:'100%', }} onClick={()=>{
           setHideCopy(!hideCopy)
         }}>

          {!hideCopy ? 
          <Box sx={{position:'absolute',left:'0.5rem',top:'0.5rem', zIndex:999}}>
            <CloseButton /> 
          </Box>
          : <Box sx={{
            display:'flex',
            position:'absolute',
            bottom:'-2px',
            left:'50%',
            transform:'translate(-50%,0)',
            border:'2px solid #858585',
            padding:'0.25rem 1.25rem',
            backgroundColor:'#fff',cursor:'pointer',
            alignItems:'center',
            boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
            borderRadius:'5px 5px 0px 0px'}}>
              <ArrowDropUpIcon />
            about us
            </Box>}
         </Box>

             {!hideCopy &&   <Copyright view='signupSheet' 
                    hide={hideCopy}
                    color={'#858585'}
                    shadow={true}
                    />  }        
              </Box>
</Container>
  
  )

}

export default ErrorPage