import { Box, Button, Card, Container, Paper, Typography,Modal, Stack, Divider } from '@mui/material'
import React, { useState, createContext ,useContext, useEffect} from "react";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { QRCodeContext } from "../../context/QRCodeProvider"
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from "../../hooks/windowDimensions";

import * as API from '../../utils/APICalls'
import * as Order from '../../utils/OrderLogic'
import * as helpers from '../../utils/helpers'
import CreateHMNIStickerAsset from '../../components/CreateHMNIStickerAsset';
import CollectNametagText from './CollectNametagText';


const AddToCartButton = (props) => {
  const item = props.item
  const { modalOpen, setModalOpen, defaultQR, currentProduct,
          setCurrentProduct,buildStep, setBuildStep,orderDefault,
          cart,setCart,currentOrder, setCurrentOrder,shop, selectedSize,storefrontData, 
          framingOpen,setFramingOpen,createCart, generateOrder,createOrder,
          updateStorefrontData} = useContext(StorefrontContext);
const { qrCodeData, updateQRCodeData, setQRCodeData } = useContext(QRCodeContext);
  const [ isAdding, setIsAdding ] = useState(false)
  const [ geo, setGeo ] = useState(false)
  const { height, width } = useWindowDimensions();
//  const [ framingModalOpen, setFramingOpen ] = useState(false)

  // order = { shop:"",
  //   cart_id:"",
  //   itemName:"",
  //   size:"",
  //   shirtType:"Bella + Canvas 3100",
  //   shirtColor:"white",
  //   printFile:"",
  //   notes:"",
  //   qr_id:"",
  //   qr_pin:"",
  //   shippingAddress: {},
  //   isComplete: false,
  //   isFulfilled: false,
  //   isShipped: false,
  //   isActivated: false,
  //   owner: ""}

  const itemNameFilter = (item,term) =>{
         return item?.name?.includes(term);
     }

  const generatecart_id = async (hexString) =>{
    hexString = await helpers.generateRandomHex(16);
    try{
      const res = await API.cartById(hexString)
      if(!res){
        console.log(hexString)
         return hexString
      }else{
        await generatecart_id(hexString)
      }
    }catch(err){console.log(err)}

  }

    const resetOrder = async() =>{
    //!---Reset QR or something for new orders
    try{
      // if(qrCodeData){
       setQRCodeData(defaultQR)
      //  updateQRCodeData(qrCodeData)
      // }
      // await setCurrentOrder({})
      //  setBuildStep(null)
      setCurrentOrder()
      setCurrentProduct(item)
      updateStorefrontData({
          ...storefrontData,
          currentOrder,
          currentProduct
          // buildStep
      })
      console.log('[AddToCart] post-order click [Storefront]:', storefrontData)
      console.log('[AddToCart] post-order click [qrCodeData]:', qrCodeData)
    } catch(err){console.log(err)}
    if(!qrCodeData?._id && !currentOrder?.qr_pin && buildStep === null){
      return true
    }else{return false}
  }


  const handleNametagAdd = async () =>{
    try{
      setFramingOpen(true)
     
    }catch(err){console.log('[handleNametagAdd] Error:',err)}
  }
   

  const processRoute = async(item,geoD) => {
      const stickerList = ["The Gridwalker","Sage","Hello My Name is","GloAun"]
      try{

        await setCurrentProduct(item)
        await updateStorefrontData({
         ...storefrontData,
           currentProduct
        })
       switch (true) {
             case itemNameFilter(item,"[ Base ]"):
                 
                 generateOrder(geoD,shop,item,selectedSize,)
                 break;
             case item?.name === "Porthole To My Heart":
                 try{
                  generateOrder(geoD,shop,item,selectedSize,)
                   
                 }catch(err){console.log(err)}
               break;
               case stickerList?.includes(item?.name):
                 try{
                   generateOrder(geoD,shop,item,selectedSize,)
                 }catch(err){console.log(err)}
                 
                 
                 break;
              
             default:
                 console.log('no process established');
         }
      }catch(err){console.log(err)}
    }

    const handleAdd = async(obj)=>{
      // e.preventDefault()
      setIsAdding(true)
      try{
        if( !cart?._id ){
          // await setCurrentProduct(item)
          const newCart = await createCart(item,'merch')
          if ( newCart){
            await updateStorefrontData({
              ...storefrontData,
              cart: newCart,
              currentProduct
            })
          }
          setIsAdding(false)
        }else if(cart?._id){
            await generateOrder(cart,item)
           setIsAdding(false)
          }else{return}
          // }else{console.log('[Not Reset 🤷]')}
        }catch(err){console.log('[handleAdd] err:',err)}
      }

   useEffect(()=>{

      // console.log('[Build Step]: ',buildStep)
  
    },[currentOrder,item,shop,qrCodeData,currentProduct])

  return (
    <>
    <Button variant='outlined' 
      disabled={isAdding}// || (modalOpen && currentOrder?.cart_id)}
      sx={{borderColor: '#757575', color:'#000'}} onClick={(e)=>{
        try{
          setCurrentProduct(item)
          updateStorefrontData({
              ...storefrontData,
                currentProduct
              })
              
            if(!helpers.itemFieldFilter(item,'tags','nameTag')){
                handleAdd(item)
              }else{
                handleNametagAdd(item)
              }

        }catch(err){console.log(err)}
      }}>{props.view !== 'product-view'?'Quick Add':item?.tags.includes('basic')||item?.tags.includes('music')?'Add to Cart':'Build Shirt'}</Button>
    
   
    </>
  )
}

export default AddToCartButton